// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  name: 'live',
  myservice: 'https://my.imexdental.de/',
  dentnetservice: 'https://backup.dent-net.de/api/',
  dentnetsearchtoken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjMsImV4cCI6MjMzMjQxMTM1MH0.srnxUXNp1tHK2W9RcetXox4rse_aeAKkB8QE9YLeaUQ'
};
